/* Sizing */
$desktopResWidth: 800px;
$tabletResWidth: 700px;
$mobileResWidth: 600px;

/* Pallette */
$black: #000000;
$white: #ffffff;
$grey: #f8f7f7;

$primary: white;
$background: $grey;
$highliteFontColor: #64696f;
$backgroundTableColor: #9a9a9a;
$tableHighliteColor: #66dbff7e;
$ankuraGreen: #619085;
$SAICBlue: #cfe6f7;

//Div Spacing
$spacingWidth: 100%;
$spacingHeight: 20px;

//Margin
$marginCenter: 0 auto;
$marginLeft: 15px;

/* Type Face */
$titleFont: Inter;
$tableFont: Rift;
@import url("https://fonts.google.com/specimen/Inter");
@import url("https://fonts.adobe.com/fonts/rift");

/* Containers */
/* App Container */
$appContainerHeight: 100%;
$appContainerBodyMargin: 0;

/* Header Container */
$headerContainerHeight: 65px;
$headerContainerPaddingTop: 1px;
$headerContainerPaddingLeft: 10px;
$headerContainerBackgroundColor: linear-gradient(to right, #1d272d, #3e565c);
$headerContainerImgMarginTop: 5px;
$headerContainerImgMarginLeft: 55px;
$headerContainerImgWidth: auto;
$headerContainerImgHeight: 40px;
$headerContainerImgPaddingTop: 5px;
$headerContainerImgFloat: left;

/* Content Container */
$contentContainerBckGrd: transparent;
$contentContainerHeight: 100%;
$contentContainerPadding: 25px;
$contentContainerMargin: 0 auto;

// Footer Container
$footerBackground: linear-gradient(to right, #3e565c, #1d272d);
$footerHeight: 150px;

/* Menu Container */
$menuIconButtonColor: $white;
$menuContainerFontSize: 17px;
$menuContainerTextDecoration: none;
$menuContainerTextAlign: center;
$menuContainerHoverBackground: #a0a0a0;

/* Divider */
$dividerShapeMargin: 0 auto;
$dividerShapeWidth: 95%;
$dividerShapeHeight: 15px;
$dividerShapeSkew: skew(-5deg);
$dividerShapeBckgrdImg: linear-gradient(to right, $primary, #0b80d4);
$dividerShapeHeight: 0.5px;

/* Page Title */
$pageTitleColor: #619085;
$pageTitleMarginTop: 12px;
$pageTitleMarginLeft: 25px;
$pageTitlePaddingTop: 15px;

/* Search Page */
$searchPageContainerMargin: 5px auto;
$searchPageContainerHeight: 100%;
$searchPageContainerPaddingBottom: 175px;
$searchBodyMargin: 0 auto;
$searchBodyHeight: 100%;
$searchBodyPaddingBottom: 75px;

/* Search */
$searchIconWidth: 100%;
$searchIconHeight: 100%;
$searchMainDisplay: flex;
$searchMainJustifyContent: center;
$searchMainAlignItems: center;
$searchBoxBorderRadius: 7px;
$searchBoxShadow: 1px 3.5px 0px rgba(177, 177, 177, 0.4);

/* FOIA Request Case Search */
$FOIARequestCaseSearchPageContainerMargin: 5px auto;
$FOIARequestCaseSearchPageContainerHeight: 100%;
$FOIARequestCaseSearchPageContainerPaddingBottom: 120px;

/* Search Button */
$searchButtonDisplay: flex;
$searchButtonJustinifyContent: center;
$searchButtonMarginTop: 5px;
$searchButtonBackgroundColor: $ankuraGreen;
$searchButtonMarginRight: 25px;
$searchButtonHeight: 40px;

/* Button Animation */
$buttonGradientDisplay: block;
$buttonGradientWidth: 100%;
$buttonGradientHeight: 100%;
$buttonGradientPosition: absolute;
$buttonGradientTop: 0;
$buttonGradientRight: 0;
$buttonGradientBottom: auto;
$buttonGradientMargin: auto;
$buttonGradientzIndex: -1;
$buttonGradientBackground: radial-gradient(
  90px circle at top center,
  #6399c0,
  #6e92ab
);
$buttonGradientTransition: all 0s ease-out 0s;

/* Table */
$tableBodyTextAlign: left;
$tableBodyBackgroundHover: #ebf5fd;
$tableHeadFontStyle: bold;
$tableHeadBackground: #016bb5;
$tableHeadColor: $white;

/* Alignment */
$centerAlignText: center;
$rightAlignPaddingRight: 35px;
$rightAlignText: right;

/* Status Pulse */
$statusPulseBorderRadius: 100%;
$statusPulseWidth: 10px;
$statusPulseHeight: 10px;
$statusPulseMarginLeft: 25px;
$statusPulseBackground: #67d240;
$statusPulseAnimation: circleFlasherPulse 2s ease infinite alternate;

/* Components */
/* Card */
$cardCompWidth: 100%;
$cardCompHeight: 350px;
$cardCompBackground: $white;
$cardCompPosition: relative;
$cardCompBackgroundHover: #e0f3ff;

/* Home Page */
$homePageContainerMargin: 0 auto;
$homePageContainerHeight: 100%;
$homePageContainerPaddingBottom: 375px;

/* Dashboard Page */
$dashboardBannerMargin: 0 auto;
$dashboardBannerHeight: 300px;
$dashboardBannerWidth: 100%;
$dashboardBannerBorderRadius: 20px;
$dashboardBannerBackgroundPosition: cover;
$dashboardBannerBackgroundSize: 100%;
$dashboardBannerBackgroundRepeat: no-repeat;
$dashboardBannerImgMargin: 20px 0 0 200px;
$dashboardBannerImgWidth: 60%;
$dashboardBannerImgHeight: auto;
$dashboardBannerH5FontSize: 32px;
$dashboardBannerH5Color: $white;
$dashboardBannerH5PaddingBottom: 10px;
$dashboardBannerH5AlignItems: center;
$dashboardBannerH5TextAlign: center;
$dashboardIntroMsgMargin: 45px auto;
$dashboardIntroMsgWidth: 625px;
$dashboardIntroMsgFontSize: 24px;
$dashboardIntroMsgColor: $white;
$dashboardIntroMsgTextAlign: left;
$dashboardIntroMsgFontWeight: bold;
$dashboardIntroMsgLineHeight: normal;
$dashBoardIntroMsgFontFamily: "Times New Roman", Times, serif;
$dashboardIntroMsgTextShadow: #333 1px 1px;
$dashboardContainerFOIATableMargin: 0 auto;

/* Dashboard Tablet Media Container */

/* Dashboard Tiles */
$dashboardContainerTilesH4Padding: 25px auto;
$dashboardContainerTilesH4FontSize: 36px;
$dashboardContainerTilesH4Color: #585757;
$dashboardContainerTilesH4MarginTop: 5px;
$dashboardContainerTilesH4FontWeight: bolder;
$dashboardContainerTilesBodyMarginTop: 3px;
$dashboardContainerTilesBodyFontSize: 1.5em;
$dashboardContainerTilesBodyFontWeight: 500;
$dashboardContainerTilesBodyColor: #64696f;
$dashboardContainerTilesBodyTextAlign: center;
$dashboardContainerTilesBodyPadding: 25px auto;
$dashboardButtonRootMarginTop: 35px;
$dashboardButtonRootPadding: 13px;
$dashboardButtonRootWidth: 150px;
$dashboardButtonRootHeight: 45px;

/* CASE SEARCH */
$dashboardCaseSearchPadding: 30px;
$dashboardCaseSearchHeight: 285px;
$dashboardCaseSearchWidth: 85%;
$dashboardCaseSearchBorderRadius: 19px;
$dashboardCaseSearchBackgroundHover: #95d7f3;

/* CREATE CASE */
$dashboardCreateCasePadding: 30px;
$dashboardCreateCaseHeight: 215px;
$dashboardCreateCaseWidth: 15%;
$dashboardCreateCaseBorderRadius: 19px;
$dashboardCreateCaseBackgroundHover: #95d7f3;

/* FOIA Page */
$foiaPageContainerMargin: 0 auto;
$foiaPageContainerHeight: 100%;
$foiaPageContainerPaddingBottom: auto;

/* Case Details Page */
$caseDetailsPageContainerMargin: 0 auto;
$caseDetailsPageContainerHeight: auto;

/* Redact Page */
$redactPageContainerMargin: 0 auto;
$redactPageContainerHeight: 100%;
$redactPageContainerPaddingBottom: 75px;

/* Upload Page */
$uploadContentContainerMargin: 0 auto;
$uploadContainerMargin: 0 auto;
$uploadContainerAlignItems: center;
$uploadContainerPadding: 50px;
$uploadContainerBackground: #f4f4f4;
$uploadContainerBorderRadius: 10px;

/* FOIA Case details page */
$foiaCaseDetailsContainerMargin: 0 auto;
$foiaCaseDetailsContainerHeight: 100%;

/* Data Table */
$dataTableContainerButtonPadding: 15px;
$dataTableContainerButtonWidth: 150px;
$dataTableContainerButtonBackground: #bcd63e;
$dataTableContainerButtonMarginLeft: 50px;

/* FILE UPLOAD */
$dropzoneDisplay: flex;
$dropzoneFlexDirection: column;
$dropzoneAlignItems: center;
$dropzoneJustifyContent: center;
$dropzoneHeight: 345px;
$dropzoneBorder: 2px dashed $backgroundTableColor;
$dropzonePadding: 2rem;
$dropzoneBorderRadius: 10px;
$dropzoneBackgroundColor: #d5dce3;
$dropzoneMargin: 0 auto;
$dropzoneTextAlign: center;
$dropzoneHoverBackground: #eef1f4;

/* List Item */
$liDisplay: flex;
$liPadding: 0;
$liMargin: 8px 0;
$liMinWidth: 300px;
$liFontSize: 22px;
$liBackgroundColor: #d4d2d2;
$liBorderRadius: 10px;
$liCursor: grab;
$liH3Padding: 10px;
$liH3Margin: 0;
$liSpanMarginRight: 10px;
$liSpanFontSize: 24px;
$liSpanFontWeight: bold;
$liSpanBorderRight: 2px solid $white;
$liSpanPaddingRight: 16px;
$liSpanPadding: 10px;
