@import "./variables.scss";

/* Spacing */
.spacer {
  background: transparent;
  width: $spacingWidth;
  height: $spacingHeight;
}

.dividerShape {
  margin: $dividerShapeMargin;
  width: $dividerShapeWidth;
  height: $dividerShapeHeight;
  background-image: linear-gradient(
    to right,
    transparent,
    #82368c,
    transparent
  );
  opacity: 0.2;
}

.dividerShape2 {
  margin: $dividerShapeMargin;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    #b3dbf8,
    transparent
  );
}

.dividerShape3 {
  width: 95%;
  height: 1px;
  background: #cfcfcf;
}

/* Page Title */
.pageTitleContainer {
  width: 100%;
  height: 100%;
  background: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Typography */
.pageTitle {
  margin-top: $pageTitleMarginTop;
  margin-left: $pageTitleMarginLeft;
  padding-top: $pageTitlePaddingTop;
  color: $pageTitleColor;
  font-variant-caps: all-small-caps;
}

/* Margin */
.marginCenter {
  margin: $marginCenter;
}

.marginLeft {
  margin-left: $marginLeft;
}

/* Cards */
.MuiCard-root {
  border-top: 5px $primary solid;
}

/* Bubble Boxes */
.validationBubble {
  font-family: arial, sans-serif;
  font-size: 1.1em;
  color: #fff;
  background: #b54f58;
  position: relative;
  padding: 20px;
  border-radius: 7px;
  max-width: 320px;
  font-size: 15px;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.validationBubble::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  color: #fff;
}

.bottom.validationBubble::after {
  border-top-color: #b54f58;
  border-bottom: 0;
  bottom: -20px;
  left: 50%;
  margin-left: -90px;
}

.searchResultsBubble {
  font-family: arial, sans-serif;
  font-size: 1.1em;
  color: $white;
  background: #619085;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  max-width: 320px;
  font-size: 18px;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  float: left;
  margin: 0 auto;
}

.searchResultsBubble::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  color: #fff;
}

.bottom.searchResultsBubble::after {
  // border-top-color: $primary;
  border-bottom: 0;
  bottom: -10px;
  left: 50%;
  margin-left: -20px;
}

.filesAddedResultsBubble {
  margin-top: -125px;
  font-family: arial, sans-serif;
  font-size: 1.1em;
  color: $primary;
  background: #cbdae6;
  position: absolute;
  width: 200px;
  padding: 20px;
  border-radius: 10px;
  max-width: 320px;
  font-size: 18px;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.filesAddedResultsBubble::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  color: #fff;
}

.bottom.filesAddedResultsBubble::after {
  border-top-color: #cbdae6;
  border-bottom: 0;
  bottom: -10px;
  left: 50%;
  margin-left: -20px;
}

.messageBubble {
  margin: 0 auto;
  align-items: center;
  text-align: center;
  font-family: arial, sans-serif;
  font-size: 14px;
  color: #fff;
  background: $primary;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  font-size: 18px;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.messageBubble::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  color: #fff;
}

.bottom.messageBubble::after {
  border-top-color: $primary;
  border-bottom: 0;
  bottom: -20px;
  left: 50%;
  margin-left: -20px;
}

/* Floating */
.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin: 0 auto;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

/* Animations */
.fade {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 4s linear forwards;
  opacity: 0;
  font-family: $titleFont;
  font-size: 25px;
  color: #ed3939;
}

@-webkit-keyframes fadeinout {
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  50% {
    opacity: 1;
  }
}

.fadePanel {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spin {
  -webkit-animation: spin 7s linear infinite;
  -moz-animation: spin 7s linear infinite;
  animation: spin 7s linear infinite;
}

.spin-back {
  -webkit-animation: spin-back 7s linear infinite;
  -moz-animation: spin-back 7s linear infinite;
  animation: spin-back 7s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin-back {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.MuiToggleButtonGroup-root {
  width: 150px;
  height: 10px;
  margin-top: -7px;
}

.MuiToggleButton-root {
  height: 10px;
  width: 200px;
  font-size: 10px;
}

/* Table */
.MuiTableBody-root {
  text-align: $tableBodyTextAlign;
}

.MuiTableBody-root .download:hover {
  background: $tableBodyBackgroundHover;
}

.MuiTableHead-root .MuiTableCell-root {
  font-style: $tableHeadFontStyle;
  background: $tableHeadBackground;
  color: $tableHeadColor;
}

/* Alignment */
.centerAlign {
  text-align: $centerAlignText;
}

.rightAlign {
  padding-right: $rightAlignPaddingRight;
  text-align: $rightAlignText;
}

/* Capitalize */
.capitalize::first-letter {
  text-transform: capitalize;
}

/* Ellipsis */
.truncate {
  color: $primary;
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Typography */
// .MuiTypography-h6 {
//   color: $MUIH6Color;
// }

.file {
  color: $primary;
}

/* Flip card Animation */
.card {
  position: absolute;
  top: 140px;
  left: 150px;
  width: 100%;
  height: 100%;
  margin: -150px;
  float: left;
  perspective: 500px;
  background: #fff;
}

.content {
  padding-top: 25px;
  padding-bottom: 25px;
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: transform 1s;
  transform-style: preserve-3d;
}

.fileType {
  color: $primary;
  width: 25%;
}

.card:hover .front {
  transition: transform 0.5s;
  background: #f8f8f8;
  animation: fadeInAnimation ease 2s;
  width: 100%;
  height: 100%;
}

.front {
  position: absolute;
  height: 100%;
  text-align: left;
}

// loading

.loadingContainer {
  width: 100%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin: 0.19em;
  background: #3e565c;
  border-radius: 0.6em;
  animation: loading 1s infinite alternate;
}

.loading h2 {
  color: $primary;
  font-size: 16px;
}

.loading span:nth-of-type(2) {
  background: #2c4d71;
  animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
  background: #619085;
  animation-delay: 0.4s;
}
.loading span:nth-of-type(4) {
  background: #82368c;
  animation-delay: 0.6s;
}
.loading span:nth-of-type(5) {
  background: #dfb947;
  animation-delay: 0.8s;
}
.loading span:nth-of-type(6) {
  background: #d38b49;
  animation-delay: 1s;
}
.loading span:nth-of-type(7) {
  background: #b54f58;
  animation-delay: 1.2s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Dimensions */
.maxWidth {
  width: 100%;
}

/* Button Animation */
.gradient {
  background: linear-gradient(130deg, #ebf7ff, #ffffff, #ebf7ff);
  background-size: 200% 200%;

  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
/* Table  */
/* editableTable */
.editableTable {
  margin: 0 auto;
}

.editableTable .MuiTableRow-root:hover {
  background: #ddf1ff;
}

/* Status Pulse Effects */
.statusGreenPulse {
  border-radius: $statusPulseBorderRadius;
  width: $statusPulseWidth;
  height: $statusPulseHeight;
  margin-left: $statusPulseMarginLeft;
  background: $statusPulseBackground;
  animation: circleFlasherGreenPulse 2s ease infinite alternate;
}

@keyframes circleFlasherGreenPulse {
  0%,
  100% {
    background-color: #67d240;
  }

  25% {
    background-color: #8bec68;
  }

  50% {
    background-color: #a4f188;
  }

  75% {
    background-color: #e3ffd9;
  }
}

// Orange
.statusOrangePulse {
  border-radius: $statusPulseBorderRadius;
  width: $statusPulseWidth;
  height: $statusPulseHeight;
  margin-left: $statusPulseMarginLeft;
  background: orange;
  animation: circleFlasherOrangePulse 2s ease infinite alternate;
}

@keyframes circleFlasherOrangePulse {
  0%,
  100% {
    background-color: orange;
  }

  25% {
    background-color: #ffc04b;
  }

  50% {
    background-color: #ffd17c;
  }

  75% {
    background-color: #ffe7bc;
  }
}

// Red
.statusRedPulse {
  border-radius: $statusPulseBorderRadius;
  width: $statusPulseWidth;
  height: $statusPulseHeight;
  margin-left: $statusPulseMarginLeft;
  background: red;
  animation: circleFlasherRedPulse 2s ease infinite alternate;
}

@keyframes circleFlasherRedPulse {
  0%,
  100% {
    background-color: red;
  }

  25% {
    background-color: #ff4444;
  }

  50% {
    background-color: #ff8181;
  }

  75% {
    background-color: #ffbebe;
  }
}

// Circle Loader
.loaderRing {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: transparent;
  border: 3px solid #cdcdcd;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 14px;
  color: $primary;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #c5c5c5;
  box-shadow: 0 0 20px rgba(189, 189, 189, 0.5);
}
.loaderRing:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid $primary;
  border-right: 3px solid $primary;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}
.loaderRing span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animateRing 2s linear infinite;
}
.loaderRing span:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $primary;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px $primary;
}

// Circular Loader 2
.loaderRing2 {
  position: absolute;
  top: 1600px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: #fff;
  border: 3px solid #cdcdcd;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 12px;
  color: $primary;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #c5c5c5;
  box-shadow: 0 0 20px rgba(189, 189, 189, 0.5);
}
.loaderRing2:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid $primary;
  border-right: 3px solid $primary;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}
.loaderRing2 span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animateRing 2s linear infinite;
}
.loadeloaderRing2rRing span:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $primary;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px $primary;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animateRing {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

.MuiListSubheader-root {
  background: $primary;
  color: #fff;
  border-radius: 3px 3px 20px 20px;
  margin-left: 100px;
  height: 50px;
  width: 130px;
}

.updateCasesContainer {
  height: 100%;
}

//Case File Dashboard Card
.MuiCard-root {
  border-top: none;
}

// Intro Animation

/* Typing */

.css-typing p {
  border-right: 0.2em solid $primary;
  font-family: Consolas, monospace;
  font-size: 19px;
  white-space: nowrap;
  overflow: hidden;
  color: #64696f;
  margin-top: 15px;
}
.css-typing p:nth-child(1) {
  width: 7.3em;
  -webkit-animation: type 3s steps(12, end);
  animation: type 2s steps(12, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid $primary;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid $primary;
  }
  100% {
    border: none;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}

.typing-demo {
  width: 30ch;
  animation: typing 3s steps(30), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: Consolas, monospace;
  font-size: 19px;
  color: #64696f;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes removeTyping {
  from {
    width: 100%;
  }
  90%,
  to {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
