@import "./variables.scss";
@import "./common.scss";

body {
  background: $primary;
  margin: $appContainerBodyMargin;
}

.appContainer {
  height: $appContainerHeight;
}

/* Header Container */
.headerContainer {
  height: $headerContainerHeight;
  padding-top: $headerContainerPaddingTop;
  padding-left: $headerContainerPaddingLeft;
  background: $headerContainerBackgroundColor;
}

.headerContainer img {
  margin-top: $headerContainerImgMarginTop;
  margin-left: $headerContainerImgMarginLeft;
  padding-top: $headerContainerImgPaddingTop;
  height: $headerContainerImgHeight;
  width: $headerContainerImgWidth;
  float: $headerContainerImgFloat;
}

.seal {
  margin-top: 50px;
  float: right;
}

.seal img {
  width: 100%;
}

/* Footer Container */
.footer {
  height: $footerHeight;
  background: $footerBackground;
  position: fixed;
}

/* Menu Container */
.menuContainer .MuiIconButton-root {
  color: $menuIconButtonColor;
}

.menuContainer a,
a:link,
a:visited {
  color: $primary;
  font-size: $menuContainerFontSize;
  text-decoration: $menuContainerTextDecoration;
  text-align: $menuContainerTextAlign;
}

.menuContainer a:hover {
  color: $primary;
  font-size: $menuContainerFontSize;
  text-decoration: $menuContainerTextDecoration;
  background: $menuContainerHoverBackground;
  text-align: $menuContainerTextAlign;
}

/* Content Container */
.contentContainer {
  margin: $contentContainerMargin;
  height: $contentContainerHeight;
  padding: $contentContainerPadding;
  background: $background;
  background-image: url("../images/Vectors.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 600px;
}

/* Card component */
.cardComponent {
  width: $cardCompWidth;
  height: $cardCompHeight;
  background: $cardCompBackground;
  position: $cardCompPosition;
}

.cardComponent:hover {
  background: $cardCompBackgroundHover;
}

/* Search Page */
.searchContainer {
  margin: $searchPageContainerMargin;
  height: $searchPageContainerHeight;
  padding-bottom: $searchPageContainerPaddingBottom;
}

.searchBox {
  border-radius: $searchBoxBorderRadius;
  box-shadow: $searchBoxShadow;
  background-color: $white;
}

.searchBody {
  margin: $searchBodyMargin;
  height: $searchBodyHeight;
  padding-bottom: $searchBodyPaddingBottom;
}

.search-icon::before {
  content: url(data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTUuNTU4IDUxNS41NTgiIHZpZXdCb3g9IjAgMCA1MTUuNTU4IDUxNS41NTgiIGZpbGw9IiNmZmYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTM3OC4zNDQgMzMyLjc4YzI1LjM3LTM0LjY0NSA0MC41NDUtNzcuMiA0MC41NDUtMTIzLjMzMyAwLTExNS40ODQtOTMuOTYxLTIwOS40NDUtMjA5LjQ0NS0yMDkuNDQ1cy0yMDkuNDQ0IDkzLjk2MS0yMDkuNDQ0IDIwOS40NDUgOTMuOTYxIDIwOS40NDUgMjA5LjQ0NSAyMDkuNDQ1YzQ2LjEzMyAwIDg4LjY5Mi0xNS4xNzcgMTIzLjMzNy00MC41NDdsMTM3LjIxMiAxMzcuMjEyIDQ1LjU2NC00NS41NjRjMC0uMDAxLTEzNy4yMTQtMTM3LjIxMy0xMzcuMjE0LTEzNy4yMTN6bS0xNjguODk5IDIxLjY2N2MtNzkuOTU4IDAtMTQ1LTY1LjA0Mi0xNDUtMTQ1czY1LjA0Mi0xNDUgMTQ1LTE0NSAxNDUgNjUuMDQyIDE0NSAxNDUtNjUuMDQzIDE0NS0xNDUgMTQ1eiIvPjwvc3ZnPg==);
  width: $searchIconWidth;
  height: $searchIconHeight;
}

.main {
  display: $searchMainDisplay;
  justify-content: $searchMainJustifyContent;
  align-items: $searchMainAlignItems;
}

.searchButton {
  display: $searchButtonDisplay;
  justify-content: $searchButtonJustinifyContent;
  margin-top: $searchButtonMarginTop;
  color: $white;
  background-color: $searchButtonBackgroundColor;
  margin-right: $searchButtonMarginRight;
  height: $searchButtonHeight;
}

.searchButton:hover {
  display: $searchButtonDisplay;
  justify-content: $searchButtonJustinifyContent;
  margin-top: $searchButtonMarginTop;
  color: $ankuraGreen;
  background-color: $SAICBlue;
  margin-right: $searchButtonMarginRight;
}

.searchContainer .input {
  margin-left: 5px;
  width: 80%;
}

#listStyle {
  background-color: white;
  margin-left: 150px;
  border-radius: 7px;
  box-shadow: 1px 3.5px 0px rgba(177, 177, 177, 0.4);
  overflow-x: hidden;
  padding: 25px;
}
